import { selectSources } from 'src/utils/sources';
import { SoundRecordingDemographics_globalSoundRecordingByIsrc_analytics as Analytics } from '../definitions/SoundRecordingDemographics';

export interface DemographicsBreakdownData {
    value: number;
    percentValue: number;
}

const EMPTY_DATA = {
    age: {
        _17: { value: 0, percentValue: 0 },
        _18_22: { value: 0, percentValue: 0 },
        _23_27: { value: 0, percentValue: 0 },
        _28_34: { value: 0, percentValue: 0 },
        _35_44: { value: 0, percentValue: 0 },
        _45_59: { value: 0, percentValue: 0 },
        _60_: { value: 0, percentValue: 0 },
        UNKNOWN: { value: 0, percentValue: 0 },
        total: 0,
    },
    gender: {
        male: { value: 0, percentValue: 0 },
        female: { value: 0, percentValue: 0 },
        unknown: { value: 0, percentValue: 0 },
        total: 0,
    },
};

export interface DemographicsBreakdownAge {
    _17: DemographicsBreakdownData;
    _18_22: DemographicsBreakdownData;
    _23_27: DemographicsBreakdownData;
    _28_34: DemographicsBreakdownData;
    _35_44: DemographicsBreakdownData;
    _45_59: DemographicsBreakdownData;
    _60_: DemographicsBreakdownData;
    UNKNOWN: DemographicsBreakdownData;
    total: number;
    isrc?: string;
}

export interface DemographicsBreakdownGender {
    male: DemographicsBreakdownData;
    female: DemographicsBreakdownData;
    unknown: DemographicsBreakdownData;
    total: number;
}

interface AnalyticsWithIsrc {
    analytics?: Analytics | null;
    isrc?: string;
}

export const hasAnyValue = <T extends { total: number }>(series: T[]) =>
    series.some(({ total }) => total > 0);

export const selectDemographicsBreakdownAge = (
    analyticsWithIsrc: AnalyticsWithIsrc[]
) =>
    analyticsWithIsrc.map(({ analytics, isrc }) => {
        const { demographicBreakdown } = analytics || {};
        const emptyData = { ...EMPTY_DATA.age, isrc };

        if (!demographicBreakdown) return emptyData;

        const data = demographicBreakdown.demographics.age;
        const age = {
            _17: data?._17 ?? 0,
            _18_22: data?._18_22 ?? 0,
            _23_27: data?._23_27 ?? 0,
            _28_34: data?._28_34 ?? 0,
            _35_44: data?._35_44 ?? 0,
            _45_59: data?._45_59 ?? 0,
            _60_: data?._60_ ?? 0,
            UNKNOWN: data?.UNKNOWN ?? 0,
            isrc,
        };

        const total =
            age._17 +
            age._18_22 +
            age._23_27 +
            age._28_34 +
            age._35_44 +
            age._45_59 +
            age._60_ +
            age.UNKNOWN;

        return age
            ? {
                  _17: {
                      value: age._17,
                      percentValue: total ? age._17 / total : 0,
                  },
                  _18_22: {
                      value: age._18_22,
                      percentValue: total ? age._18_22 / total : 0,
                  },
                  _23_27: {
                      value: age._23_27,
                      percentValue: total ? age._23_27 / total : 0,
                  },
                  _28_34: {
                      value: age._28_34,
                      percentValue: total ? age._28_34 / total : 0,
                  },
                  _35_44: {
                      value: age._35_44,
                      percentValue: total ? age._35_44 / total : 0,
                  },
                  _45_59: {
                      value: age._45_59,
                      percentValue: total ? age._45_59 / total : 0,
                  },
                  _60_: {
                      value: age._60_,
                      percentValue: total ? age._60_ / total : 0,
                  },
                  UNKNOWN: {
                      value: age.UNKNOWN,
                      percentValue: total ? age.UNKNOWN / total : 0,
                  },
                  total,
                  isrc,
              }
            : emptyData;
    });

export const selectDemographicsBreakdownGender = (
    analyticsWithIsrc: AnalyticsWithIsrc[]
) =>
    analyticsWithIsrc.map(({ analytics, isrc }) => {
        const { demographicBreakdown } = analytics || {};
        const emptyData = { ...EMPTY_DATA.gender, isrc };

        if (!demographicBreakdown) return emptyData;

        const data = demographicBreakdown.demographics.gender;
        const gender = {
            male: data?.male ?? 0,
            female: data?.female ?? 0,
            unknown: data?.unknown ?? 0,
            isrc,
        };

        const total = gender.male + gender.female + gender.unknown;

        return {
            male: {
                value: gender.male,
                percentValue: total ? gender.male / total : 0,
            },
            female: {
                value: gender.female,
                percentValue: total ? gender.female / total : 0,
            },
            unknown: {
                value: gender.unknown,
                percentValue: total ? gender.unknown / total : 0,
            },
            isrc,
            total,
        };
    });

export const selectDemographicSources = (
    analyticsWithIsrc: AnalyticsWithIsrc[]
) => {
    const [first] = analyticsWithIsrc;

    return selectSources(first?.analytics?.demographicBreakdown?.sources);
};
