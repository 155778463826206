import React from 'react';
import { getCountryName, GridTable } from '@theorchard/suite-components';
import CountryCard from 'src/pages/newReleases/components/countryCard';
import { COUNTRIES_CELL_LIMIT } from 'src/pages/newReleases/constants';

export const CLASSNAME = 'NmfCountriesCell';

GridTable.defineColumnTemplate('nmfCountries', {
    align: 'center',
    Cell: ({ data: { countries } }) => {
        const countriesToShow = countries.slice(0, COUNTRIES_CELL_LIMIT);

        return (
            <div className={CLASSNAME}>
                {countriesToShow.map(({ countryCode, position }) => {
                    return (
                        <CountryCard
                            key={countryCode}
                            countryCode={countryCode}
                            tooltipMessage={getCountryName(countryCode)}
                            position={position}
                        />
                    );
                })}
            </div>
        );
    },
});
