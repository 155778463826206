/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountSummaryType {
  COUNTRY = "COUNTRY",
  IMPRINT = "IMPRINT",
  PARTICIPANT = "PARTICIPANT",
  PRODUCT = "PRODUCT",
  PROJECT = "PROJECT",
  SOS = "SOS",
  SOUND_RECORDING_FAMILY = "SOUND_RECORDING_FAMILY",
  STORE = "STORE",
  SUBACCOUNT = "SUBACCOUNT",
  SUBSCRIPTION = "SUBSCRIPTION",
  TOTAL = "TOTAL",
  TRACK = "TRACK",
}

export enum AccountTimeseriesType {
  ACCOUNT_ALBUM_DOWNLOADS = "ACCOUNT_ALBUM_DOWNLOADS",
  ACCOUNT_ALBUM_DOWNLOADS_BY_COUNTRY = "ACCOUNT_ALBUM_DOWNLOADS_BY_COUNTRY",
  ACCOUNT_ALBUM_DOWNLOADS_BY_IMPRINT = "ACCOUNT_ALBUM_DOWNLOADS_BY_IMPRINT",
  ACCOUNT_ALBUM_DOWNLOADS_BY_PARTICIPANT = "ACCOUNT_ALBUM_DOWNLOADS_BY_PARTICIPANT",
  ACCOUNT_ALBUM_DOWNLOADS_BY_PRODUCT = "ACCOUNT_ALBUM_DOWNLOADS_BY_PRODUCT",
  ACCOUNT_ALBUM_DOWNLOADS_BY_PROJECT = "ACCOUNT_ALBUM_DOWNLOADS_BY_PROJECT",
  ACCOUNT_ALBUM_DOWNLOADS_BY_STORE = "ACCOUNT_ALBUM_DOWNLOADS_BY_STORE",
  ACCOUNT_ALBUM_DOWNLOADS_BY_SUBACCOUNT = "ACCOUNT_ALBUM_DOWNLOADS_BY_SUBACCOUNT",
  ACCOUNT_STREAMS = "ACCOUNT_STREAMS",
  ACCOUNT_STREAMS_BY_COUNTRY = "ACCOUNT_STREAMS_BY_COUNTRY",
  ACCOUNT_STREAMS_BY_IMPRINT = "ACCOUNT_STREAMS_BY_IMPRINT",
  ACCOUNT_STREAMS_BY_PARTICIPANT = "ACCOUNT_STREAMS_BY_PARTICIPANT",
  ACCOUNT_STREAMS_BY_PRODUCT = "ACCOUNT_STREAMS_BY_PRODUCT",
  ACCOUNT_STREAMS_BY_PROJECT = "ACCOUNT_STREAMS_BY_PROJECT",
  ACCOUNT_STREAMS_BY_SOS = "ACCOUNT_STREAMS_BY_SOS",
  ACCOUNT_STREAMS_BY_SOUND_RECORDING_FAMILY = "ACCOUNT_STREAMS_BY_SOUND_RECORDING_FAMILY",
  ACCOUNT_STREAMS_BY_STORE = "ACCOUNT_STREAMS_BY_STORE",
  ACCOUNT_STREAMS_BY_SUBACCOUNT = "ACCOUNT_STREAMS_BY_SUBACCOUNT",
  ACCOUNT_STREAMS_BY_SUBSCRIPTION = "ACCOUNT_STREAMS_BY_SUBSCRIPTION",
  ACCOUNT_STREAMS_BY_TRACK = "ACCOUNT_STREAMS_BY_TRACK",
  ACCOUNT_TRACK_DOWNLOADS = "ACCOUNT_TRACK_DOWNLOADS",
  ACCOUNT_TRACK_DOWNLOADS_BY_COUNTRY = "ACCOUNT_TRACK_DOWNLOADS_BY_COUNTRY",
  ACCOUNT_TRACK_DOWNLOADS_BY_IMPRINT = "ACCOUNT_TRACK_DOWNLOADS_BY_IMPRINT",
  ACCOUNT_TRACK_DOWNLOADS_BY_PARTICIPANT = "ACCOUNT_TRACK_DOWNLOADS_BY_PARTICIPANT",
  ACCOUNT_TRACK_DOWNLOADS_BY_PRODUCT = "ACCOUNT_TRACK_DOWNLOADS_BY_PRODUCT",
  ACCOUNT_TRACK_DOWNLOADS_BY_PROJECT = "ACCOUNT_TRACK_DOWNLOADS_BY_PROJECT",
  ACCOUNT_TRACK_DOWNLOADS_BY_SOUND_RECORDING_FAMILY = "ACCOUNT_TRACK_DOWNLOADS_BY_SOUND_RECORDING_FAMILY",
  ACCOUNT_TRACK_DOWNLOADS_BY_STORE = "ACCOUNT_TRACK_DOWNLOADS_BY_STORE",
  ACCOUNT_TRACK_DOWNLOADS_BY_SUBACCOUNT = "ACCOUNT_TRACK_DOWNLOADS_BY_SUBACCOUNT",
  ACCOUNT_TRACK_DOWNLOADS_BY_TRACK = "ACCOUNT_TRACK_DOWNLOADS_BY_TRACK",
}

export enum AccountTopContentType {
  DOWNLOADS = "DOWNLOADS",
  STREAMS = "STREAMS",
}

export enum AccountVideoViewsQueryType {
  COUNTRY = "COUNTRY",
  PARTICIPANT = "PARTICIPANT",
  SUBACCOUNT = "SUBACCOUNT",
  TOTAL = "TOTAL",
  TRACK = "TRACK",
  TRACK_FAMILY = "TRACK_FAMILY",
  VIDEO = "VIDEO",
}

export enum AllProductSearchQueryType {
  EXACT = "EXACT",
}

export enum DatePeriod {
  ALL_TIME = "ALL_TIME",
}

export enum DeltaGrowthPeriod {
  _14_DAYS = "_14_DAYS",
  _180_DAYS = "_180_DAYS",
  _183_DAYS = "_183_DAYS",
  _1_DAY = "_1_DAY",
  _1_TO_2_MONTHS = "_1_TO_2_MONTHS",
  _28_DAYS = "_28_DAYS",
  _2_TO_3_MONTHS = "_2_TO_3_MONTHS",
  _365_DAYS = "_365_DAYS",
  _3_TO_4_MONTHS = "_3_TO_4_MONTHS",
  _7_DAYS = "_7_DAYS",
  _90_DAYS = "_90_DAYS",
  _ALL_TIME = "_ALL_TIME",
}

export enum Distributor {
  AWAL = "AWAL",
  SME = "SME",
  THEORCHARD = "THEORCHARD",
}

export enum GlobalParticipantAggregatedRankingsOrderingV2 {
  CHANGE = "CHANGE",
  CHART_COUNTRYCODE = "CHART_COUNTRYCODE",
  CHART_NAME = "CHART_NAME",
  CHART_PLATFORM = "CHART_PLATFORM",
  DAYS_ON_CHART = "DAYS_ON_CHART",
  EXIT_TIMESTAMP = "EXIT_TIMESTAMP",
  FIRST_POSITION = "FIRST_POSITION",
  FIRST_TIMESTAMP = "FIRST_TIMESTAMP",
  MOST_RECENT_POSITION = "MOST_RECENT_POSITION",
  MOST_RECENT_TIMESTAMP = "MOST_RECENT_TIMESTAMP",
  PEAK_POSITION = "PEAK_POSITION",
  PEAK_TIMESTAMP = "PEAK_TIMESTAMP",
  POSITION = "POSITION",
  PREVIOUS_POSITION = "PREVIOUS_POSITION",
  PREVIOUS_TIMESTAMP = "PREVIOUS_TIMESTAMP",
  SOUND_RECORDING_NAME = "SOUND_RECORDING_NAME",
}

export enum GlobalParticipantAnalyticsSummaryType {
  COUNTRY = "COUNTRY",
  PRODUCT = "PRODUCT",
  SOS = "SOS",
  SOUND_RECORDING = "SOUND_RECORDING",
  STORE = "STORE",
  TOTAL = "TOTAL",
}

export enum GlobalParticipantAnalyticsTimeseriesType {
  ALBUM_DOWNLOADS = "ALBUM_DOWNLOADS",
  ALBUM_DOWNLOADS_BY_COUNTRY = "ALBUM_DOWNLOADS_BY_COUNTRY",
  ALBUM_DOWNLOADS_BY_PRODUCT = "ALBUM_DOWNLOADS_BY_PRODUCT",
  ALBUM_DOWNLOADS_BY_STORE = "ALBUM_DOWNLOADS_BY_STORE",
  TRACK_DOWNLOADS = "TRACK_DOWNLOADS",
  TRACK_DOWNLOADS_BY_COUNTRY = "TRACK_DOWNLOADS_BY_COUNTRY",
  TRACK_DOWNLOADS_BY_PRODUCT = "TRACK_DOWNLOADS_BY_PRODUCT",
  TRACK_DOWNLOADS_BY_SOUND_RECORDING = "TRACK_DOWNLOADS_BY_SOUND_RECORDING",
  TRACK_DOWNLOADS_BY_STORE = "TRACK_DOWNLOADS_BY_STORE",
  TRACK_STREAMS = "TRACK_STREAMS",
  TRACK_STREAMS_BY_COUNTRY = "TRACK_STREAMS_BY_COUNTRY",
  TRACK_STREAMS_BY_PRODUCT = "TRACK_STREAMS_BY_PRODUCT",
  TRACK_STREAMS_BY_SOS = "TRACK_STREAMS_BY_SOS",
  TRACK_STREAMS_BY_SOUND_RECORDING = "TRACK_STREAMS_BY_SOUND_RECORDING",
  TRACK_STREAMS_BY_STORE = "TRACK_STREAMS_BY_STORE",
}

export enum GlobalParticipantSearchOrderByField {
  MONTHLY_LISTENERS = "MONTHLY_LISTENERS",
  POPULARITY = "POPULARITY",
  SCORE = "SCORE",
  STREAMS_28_DAYS = "STREAMS_28_DAYS",
  STREAMS_7_DAYS = "STREAMS_7_DAYS",
  STREAMS_ALL_TIME = "STREAMS_ALL_TIME",
}

export enum GlobalProductSearchOrderByField {
  RELEASE_DATE = "RELEASE_DATE",
}

export enum GlobalSoundRecordingSearchOrderByField {
  MONTHLY_LISTENERS = "MONTHLY_LISTENERS",
  POPULARITY = "POPULARITY",
  RELEASE_DATE = "RELEASE_DATE",
  SCORE = "SCORE",
  STREAMS_28_DAYS = "STREAMS_28_DAYS",
  STREAMS_7_DAYS = "STREAMS_7_DAYS",
  STREAMS_ALL_TIME = "STREAMS_ALL_TIME",
}

export enum GlobalSoundRecordingSummaryType {
  COUNTRY = "COUNTRY",
  PRODUCT = "PRODUCT",
  SOS = "SOS",
  SOS_V2 = "SOS_V2",
  STORE = "STORE",
  TOTAL = "TOTAL",
}

export enum GlobalSoundRecordingTimeseriesType {
  TRACK_DOWNLOADS = "TRACK_DOWNLOADS",
  TRACK_DOWNLOADS_BY_COUNTRY = "TRACK_DOWNLOADS_BY_COUNTRY",
  TRACK_DOWNLOADS_BY_PRODUCT = "TRACK_DOWNLOADS_BY_PRODUCT",
  TRACK_DOWNLOADS_BY_STORE = "TRACK_DOWNLOADS_BY_STORE",
  TRACK_STREAMS = "TRACK_STREAMS",
  TRACK_STREAMS_BY_COUNTRY = "TRACK_STREAMS_BY_COUNTRY",
  TRACK_STREAMS_BY_PRODUCT = "TRACK_STREAMS_BY_PRODUCT",
  TRACK_STREAMS_BY_SOS = "TRACK_STREAMS_BY_SOS",
  TRACK_STREAMS_BY_SOS_V2 = "TRACK_STREAMS_BY_SOS_V2",
  TRACK_STREAMS_BY_STORE = "TRACK_STREAMS_BY_STORE",
}

export enum HighWaterMarkType {
  DOWNLOAD_STORES = "DOWNLOAD_STORES",
  STREAMING_STORES = "STREAMING_STORES",
  VIDEO_STORES = "VIDEO_STORES",
}

export enum OrderDir {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PlaylistType {
  ALGORITHMIC = "ALGORITHMIC",
  CHART = "CHART",
  CURATED = "CURATED",
  EDITORIAL = "EDITORIAL",
  HOT_HITS = "HOT_HITS",
  NEW_MUSIC_FRIDAY = "NEW_MUSIC_FRIDAY",
  PERSONALIZED = "PERSONALIZED",
  RADIO = "RADIO",
  STATION = "STATION",
  USER_GENERATED = "USER_GENERATED",
}

export enum PotGraphType {
  AREA = "AREA",
  BAR = "BAR",
  COLUMN = "COLUMN",
  LINE = "LINE",
}

export enum ProductSummaryType {
  COUNTRY = "COUNTRY",
  SOS = "SOS",
  SOUND_RECORDING = "SOUND_RECORDING",
  STORE = "STORE",
  TOTAL = "TOTAL",
}

export enum ProductTimeseriesType {
  PRODUCT_DOWNLOADS = "PRODUCT_DOWNLOADS",
  PRODUCT_DOWNLOADS_BY_COUNTRY = "PRODUCT_DOWNLOADS_BY_COUNTRY",
  PRODUCT_DOWNLOADS_BY_STORE = "PRODUCT_DOWNLOADS_BY_STORE",
  PRODUCT_STREAMS = "PRODUCT_STREAMS",
  PRODUCT_STREAMS_BY_COUNTRY = "PRODUCT_STREAMS_BY_COUNTRY",
  PRODUCT_STREAMS_BY_SOS = "PRODUCT_STREAMS_BY_SOS",
  PRODUCT_STREAMS_BY_STORE = "PRODUCT_STREAMS_BY_STORE",
  PRODUCT_STREAMS_BY_TRACK = "PRODUCT_STREAMS_BY_TRACK",
  TRACK_DOWNLOADS = "TRACK_DOWNLOADS",
  TRACK_DOWNLOADS_BY_COUNTRY = "TRACK_DOWNLOADS_BY_COUNTRY",
  TRACK_DOWNLOADS_BY_STORE = "TRACK_DOWNLOADS_BY_STORE",
  TRACK_DOWNLOADS_BY_TRACK = "TRACK_DOWNLOADS_BY_TRACK",
}

export enum QueryType {
  exact = "exact",
}

export enum Role {
  ACCOUNT360 = "ACCOUNT360",
  ACCOUNTING = "ACCOUNTING",
  ADMINISTRATOR = "ADMINISTRATOR",
  ADVERTISING = "ADVERTISING",
  ANALYTICS = "ANALYTICS",
  AUDIENCE = "AUDIENCE",
  BULK_CREATE_DIGITAL_AUDIO = "BULK_CREATE_DIGITAL_AUDIO",
  CATALOG = "CATALOG",
  CREATE_ADMIN_TOOLS = "CREATE_ADMIN_TOOLS",
  CREATE_BUNDLE = "CREATE_BUNDLE",
  DELIVER_PHYSICAL_AUDIO = "DELIVER_PHYSICAL_AUDIO",
  DOCUMENTS = "DOCUMENTS",
  DOLBY_ATMOS_PACKAGER_ALPHA = "DOLBY_ATMOS_PACKAGER_ALPHA",
  MANAGE_DIGITAL_AUDIO = "MANAGE_DIGITAL_AUDIO",
  MANAGE_NR_DELIVERIES = "MANAGE_NR_DELIVERIES",
  MANAGE_NR_OWNERSHIP = "MANAGE_NR_OWNERSHIP",
  MANAGE_NR_REPERTOIRE = "MANAGE_NR_REPERTOIRE",
  MANAGE_RIGHTS = "MANAGE_RIGHTS",
  MARKETING = "MARKETING",
  NETWORK_ADMIN = "NETWORK_ADMIN",
  OWS_DELIVERY_METADATA = "OWS_DELIVERY_METADATA",
  PAYEE_MANAGEMENT = "PAYEE_MANAGEMENT",
  PAYMENTS = "PAYMENTS",
  PODCAST_ADMIN = "PODCAST_ADMIN",
  PRODUCER = "PRODUCER",
  PUBLISHING_COMPOSITIONS_CHANGELOG = "PUBLISHING_COMPOSITIONS_CHANGELOG",
  READ_ONLY_PRODUCER_ROLE = "READ_ONLY_PRODUCER_ROLE",
  REVIEW_DIGITAL_AUDIO = "REVIEW_DIGITAL_AUDIO",
  ROYALTIES = "ROYALTIES",
  SONGWHIP = "SONGWHIP",
  TOOLS_ARTIST_NAME_CHANGE = "TOOLS_ARTIST_NAME_CHANGE",
  VIEW_ORCHARD_SOUND_RECORDING = "VIEW_ORCHARD_SOUND_RECORDING",
}

export enum SortDir {
  ASC = "ASC",
  DESC = "DESC",
  asc = "asc",
  desc = "desc",
}

export enum StreamSourceFilter {
  ALBUM = "ALBUM",
  ALBUMPAGE = "ALBUMPAGE",
  ARTIST = "ARTIST",
  ARTISTPAGE = "ARTISTPAGE",
  CHART = "CHART",
  COLLECTION = "COLLECTION",
  DAILYMIX = "DAILYMIX",
  DISCOVERWEEKLY = "DISCOVERWEEKLY",
  DISCOVERY = "DISCOVERY",
  EXTERNAL = "EXTERNAL",
  LIBRARY = "LIBRARY",
  MUSICKIT = "MUSICKIT",
  NOWPLAYING = "NOWPLAYING",
  OTHER = "OTHER",
  PLAYLIST = "PLAYLIST",
  PLAYLISTS = "PLAYLISTS",
  PLAYQUEUE = "PLAYQUEUE",
  RADIO = "RADIO",
  RELEASERADAR = "RELEASERADAR",
  SEARCH = "SEARCH",
  SONGS = "SONGS",
  STATION = "STATION",
  USERPLAYLIST = "USERPLAYLIST",
  VOICE = "VOICE",
}

export enum StreamSources {
  ACTIVE = "ACTIVE",
  COLLECTION = "COLLECTION",
  PASSIVE = "PASSIVE",
  UNKNOWN = "UNKNOWN",
}

export enum SubscriptionType {
  ADSUPPORTED = "ADSUPPORTED",
  MIDTIER = "MIDTIER",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export enum TiktokAnalyticsTimeseriesType {
  ALL = "ALL",
  BY_CONTENT_TYPE = "BY_CONTENT_TYPE",
  BY_COUNTRY = "BY_COUNTRY",
}

export enum TrackOfferType {
  ALBUM_DOWNLOAD_ONLY = "ALBUM_DOWNLOAD_ONLY",
  ALBUM_DOWNLOAD_STREAM = "ALBUM_DOWNLOAD_STREAM",
  ALBUM_TRACK_DOWNLOAD = "ALBUM_TRACK_DOWNLOAD",
  ALL = "ALL",
  NONE = "NONE",
  STREAM_ONLY = "STREAM_ONLY",
  TRACK_DOWNLOAD_ONLY = "TRACK_DOWNLOAD_ONLY",
  TRACK_DOWNLOAD_STREAM = "TRACK_DOWNLOAD_STREAM",
}

export enum TrackType {
  MUSIC = "MUSIC",
  VIDEO = "VIDEO",
}

/**
 * This enum represents list of possible upper profit centers for filtering the response.
 * There is mapping defined product -> upper profit center.
 */
export enum UpperProfitCenter {
  ARIOLA_HANSA = "ARIOLA_HANSA",
  CATALOG = "CATALOG",
  CENTURY_MEDIA = "CENTURY_MEDIA",
  CLASSIC = "CLASSIC",
  COLUMBIA = "COLUMBIA",
  COMEDY = "COMEDY",
  EPIC = "EPIC",
  FAMILY_ENTERT = "FAMILY_ENTERT",
  FAMOUZ = "FAMOUZ",
  FOUR_MUSIC = "FOUR_MUSIC",
  GOLD_LEAGUE = "GOLD_LEAGUE",
  JIVE = "JIVE",
  MEDIA_COMPILATIONS = "MEDIA_COMPILATIONS",
  NITRON = "NITRON",
  RCA = "RCA",
  _1991_ARTIST_LABEL = "_1991_ARTIST_LABEL",
}

export enum VendorType {
  CATALOG = "CATALOG",
  CLIENT_SERVICES = "CLIENT_SERVICES",
  D3 = "D3",
  FILM = "FILM",
  FRONTLINE = "FRONTLINE",
  TEST = "TEST",
  TV = "TV",
}

export enum ViewsType {
  AD_SUPPORTED = "AD_SUPPORTED",
  ALL = "ALL",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export interface ChartFilterInput {
  country?: string | null;
  frequency?: string | null;
  genre?: string | null;
  id?: string | null;
  platform?: string | null;
  target?: string | null;
  type?: string | null;
}

/**
 * Filter for the placments on a chart on a particular date.
 * 
 * If multiple conditions are specified, they are logically AND-ed.
 */
export interface ChartV2DatePlacementFilterInput {
  brandUuidIn?: string[] | null;
  isPartOfCatalog?: boolean | null;
  positionChange?: NullableIntFilterInput | null;
  positionChangeAbs?: NullableIntFilterInput | null;
}

export interface GlobalParticipantSearchFilterInput {
  companyBrandUUIDs?: string[] | null;
  companyBrandUuid?: string | null;
  countries?: string[] | null;
  followers?: NumberRangeInput | null;
  labelIds?: LabelIdFilterInput[] | null;
  labelName?: string | null;
  monthlyListeners?: NumberRangeInput | null;
  popularity?: NumberRangeInput | null;
  streams28Days?: NumberRangeInput | null;
  streams7Days?: NumberRangeInput | null;
  streamsAllTime?: NumberRangeInput | null;
}

export interface GlobalParticipantSearchOrderByInput {
  dir?: OrderDir | null;
  field: GlobalParticipantSearchOrderByField;
}

export interface GlobalProductSearchFilterInput {
  companyBrandUUIDs?: string[] | null;
}

export interface GlobalProductSearchOrderByInput {
  dir?: OrderDir | null;
  field: GlobalProductSearchOrderByField;
}

export interface GlobalSoundRecordingFamilyAnalyticsFilterInput {
  companyBrandId?: string | null;
  countryCodes?: string[] | null;
  distributors?: Distributor[] | null;
  finLabelIds?: string[] | null;
  globalParticipantIds?: string[] | null;
  labelIds?: string[] | null;
  parentCompanyId?: string | null;
  serviceTierId?: string | null;
  storeIds?: string[] | null;
  upperProfitCenters?: UpperProfitCenter[] | null;
}

export interface GlobalSoundRecordingSearchFilterInput {
  companyBrandUUIDs?: string[] | null;
  companyBrandUuid?: string | null;
  countries?: string[] | null;
  followers?: NumberRangeInput | null;
  globalParticipantIds?: string[] | null;
  labelIds?: LabelIdFilterInput[] | null;
  labelName?: string | null;
  monthlyListeners?: NumberRangeInput | null;
  participantName?: string | null;
  popularity?: NumberRangeInput | null;
  releaseDate?: StringRangeInput | null;
  streams28Days?: NumberRangeInput | null;
  streams7Days?: NumberRangeInput | null;
  streamsAllTime?: NumberRangeInput | null;
}

export interface GlobalSoundRecordingSearchOrderByInput {
  dir?: OrderDir | null;
  field: GlobalSoundRecordingSearchOrderByField;
}

export interface LabelIdFilterInput {
  subaccountId?: number | null;
  vendorId?: number | null;
}

export interface LabelIdInput {
  subaccountId: number;
  vendorId?: number | null;
}

/**
 * Filters against the value of a nullable int. If multiple filters are provided,
 * they are logically AND-ed.
 * 
 * E.g. `{ lt: 10, gte: 5 }` represents the range `[5, 10)`
 */
export interface NullableIntFilterInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
}

export interface NumberRangeInput {
  from?: GQLLong | null;
  to?: GQLLong | null;
}

/**
 * ResourceAccessInput describes resource access structure that should be added or updated for identity.
 */
export interface ResourceAccessInput {
  roles: Role[];
  type: string;
  updatedOn?: GQLDateTime | null;
  uuid: string;
}

export interface SocialMediaUpdate {
  input: string;
  platform: string;
}

export interface StringRangeInput {
  from?: string | null;
  to?: string | null;
}

export interface TopGlobalSoundRecordingFamiliesPaginationInput {
  limit: number;
  offset: number;
  orderBy: string;
  orderDir: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
