import React from 'react';
import { Card, Tooltip } from '@theorchard/suite-components';
import { CountryFlag } from '@theorchard/suite-icons';
import cx from 'classnames';
import { EMPTY_CHAR } from 'src/constants';

export const CLASSNAME = 'CountryCard';
export const NOT_FEATURED_CLASSNAME = `${CLASSNAME}-not-featured`;
export const WITH_MIN_WIDTH_CLASSNAME = `${CLASSNAME}-with-minWidth`;
export const CONTENT_CLASSNAME = `${CLASSNAME}-content`;
export const POSITION_CLASSNAME = `${CLASSNAME}-position`;
export const COUNTRY_CLASSNAME = `${CLASSNAME}-country`;
export const COUNTRY_CONTAINER_CLASSNAME = `${COUNTRY_CLASSNAME}-container`;

export interface CountryCardProps {
    countryCode: string;
    position?: string | number | null;
    withMinWidth?: boolean;
    tooltipMessage: string;
}

const CountryCard: React.FC<CountryCardProps> = ({
    countryCode,
    position,
    withMinWidth = false,
    tooltipMessage,
}) => {
    return (
        <Card
            className={cx(CLASSNAME, {
                [NOT_FEATURED_CLASSNAME]: !position,
                [WITH_MIN_WIDTH_CLASSNAME]: withMinWidth,
            })}
        >
            <Tooltip
                id={`${CLASSNAME}-${countryCode}-tooltip`}
                message={tooltipMessage}
                placement="bottom"
            >
                <Card.Body className={CONTENT_CLASSNAME}>
                    <h4 className={POSITION_CLASSNAME}>
                        {position ? position : EMPTY_CHAR}
                    </h4>
                    <div className={COUNTRY_CONTAINER_CLASSNAME}>
                        <CountryFlag countryCode={countryCode} />
                        <p>{countryCode}</p>
                    </div>
                </Card.Body>
            </Tooltip>
        </Card>
    );
};

export default CountryCard;
