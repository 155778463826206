export const COUNTRIES_LIST = [
    'ZA',
    'MGH',
    'NG',
    'KE',
    'GH',
    'BD',
    'IN',
    'PH',
    'TW',
    'ID',
    'MY',
    'PK',
    'TH',
    'HK',
    'SG',
    'LK',
    'VN',
    'JP',
    'KR',
    'AU',
    'NZ',
    'CZ',
    'DE',
    'GR',
    'NL',
    'IT',
    'FR',
    'PL',
    'RO',
    'CH',
    'BE',
    'HU',
    'MX',
    'BR',
    'ES',
    'CO',
    'PT',
    'PE',
    'SUR',
    'LAT',
    'TR',
    'IL',
    'GULF',
    'EG',
    'LEV',
    'SE',
    'NO',
    'DK',
    'FI',
    'IS',
    'US',
    'CA',
    'UA',
    'GB',
];

export const COUNTRY_OPTIONS = COUNTRIES_LIST.map(countryCode => ({
    countryCode,
}));

export const COUNTRIES_CELL_LIMIT = 10;
