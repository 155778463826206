import React from 'react';
import { CoverArt, GridTable, Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import { concatNames } from 'src/apollo/selectors';
import LinkList from 'src/components/linkList';
import SongLinkCell from 'src/components/table/cells/songLinkCell';
import { ROUTE_PARTICIPANT } from 'src/constants';

GridTable.defineColumnTemplate('thumbnailSongAndArtistLink', {
    align: 'left',
    Cell: ({ data, ...rest }) => {
        const areParticipantsAvailable = data.participants?.length !== 0;

        return (
            <div className="ThumbnailSongAndArtistLinkCell CoverArtCell">
                <CoverArt url={data.imageUrl} width="40" />
                <div className="ThumbnailSongAndArtistLinkCell-data">
                    <SongLinkCell data={data} {...rest} />
                    {areParticipantsAvailable && (
                        <Tooltip
                            id={`availableParticipants_${uniqueId()}`}
                            message={concatNames(data.participants)}
                        >
                            <LinkList
                                route={ROUTE_PARTICIPANT}
                                links={data.participants}
                                separator="comma"
                                className="SongMetaDataCell-links"
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    },
});
